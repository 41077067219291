.container {
    font-family: sans-serif;
    padding: 20px;
    margin: auto;
    max-width: 960px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .header {
    text-align: center;
    background-color: #003366;
    color: white;
    padding: 30px 0;
    border-radius: 8px;
  }
  
  .main {
    flex-grow: 1;
    margin-top: 20px;
  }
  
  .main h2 {
    color: #003366;
  }
  
  ul {
    list-style-type: square;
    padding-left: 20px;
  }
  
  .footer {
    text-align: center;
    font-size: 0.9em;
    margin-top: 30px;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    color: #666;
  }
  